import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/src/trpc/TRPCReactProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material-nextjs@6.4.3_@emotion+cache@11.14.0_@emotion+react@11.14.0_@types+react@18.3.18_pd7grbflkvq5zc7hw4mc7dxfj4/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-auth@5.0.0-beta.19_next@14.2.4_@playwright+test@1.50.1_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.4_@babel+core@7.26.8_@playwright+test@1.50.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/font.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../public/fonts/Montreal-Regular.woff\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../public/fonts/Montreal-Bold.woff\",\"weight\":\"700\",\"style\":\"normal\"}],\"variable\":\"--font-montreal\"}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NuqsAdapter"] */ "/vercel/path0/node_modules/.pnpm/nuqs@2.3.2_next@14.2.4_@playwright+test@1.50.1_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/nuqs/dist/adapters/next/app.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/src/styles/globals.css");
