/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const isNullOrUndefined = (
  value: unknown
): value is null | undefined => {
  return value === null || value === undefined;
};

type ObjectValues<T extends object> = T[keyof T];

export const isNotNullOrUndefined = <T>(
  value: T
): value is Exclude<T, null | undefined> => {
  return value !== null && value !== undefined;
};

export const isObject = (value: unknown): value is object => {
  return (
    isNotNullOrUndefined(value) &&
    typeof value === "object" &&
    !Array.isArray(value)
  );
};

export const isString = (value: unknown): value is string => {
  return isNotNullOrUndefined(value) && typeof value === "string";
};

export const isBoolean = (value: unknown): value is boolean => {
  return isNotNullOrUndefined(value) && typeof value === "boolean";
};

export const isNumber = (value: unknown): value is number => {
  return isNotNullOrUndefined(value) && typeof value === "number";
};

export const isArray = (value: unknown): value is unknown[] => {
  return isNotNullOrUndefined(value) && Array.isArray(value);
};

export const isKeyIn = <T extends object>(
  item: T,
  key: string | number | symbol | undefined | null
): key is keyof T => {
  if (isNullOrUndefined(item)) return false;
  if (isNullOrUndefined(key)) return false;

  if (!isObject(item)) return false;

  return key in item;
};

export const getObjectValues = <TObject extends object>(object?: TObject) => {
  if (isNullOrUndefined(object)) return [];
  return Object.values(object) as Array<TObject[keyof TObject]>;
};

export const getObjectKeys = <TObject extends object>(object?: TObject) => {
  if (isNullOrUndefined(object)) return [];
  return Object.keys(object) as Array<keyof TObject>;
};

export const getObjectEntries = <TObject extends object>(object?: TObject) => {
  if (isNullOrUndefined(object)) return [];
  return Object.entries(object) as Array<
    [keyof TObject, ObjectValues<TObject>]
  >;
};

export const getNestedValue = <T, P extends Flatten<T>>(
  obj: T,
  path: P
): NestedValue<T, P> => {
  const parts = path.split(".");

  let result: any = obj;

  for (const part of parts) {
    result = result[part];
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return result;
};

export function setNestedValue<
  T extends Record<string, any>,
  K extends Flatten<T>,
>(
  obj: T,
  path: K,
  value: Partial<NestedValue<T, K>> | NestedValue<T, K> | undefined | null
): T {
  const newObj = { ...obj };
  const parts = path.toString().split(".");
  let current: any = newObj;

  for (let i = 0; i < parts.length - 1; i++) {
    const part = parts[i] as keyof typeof current;
    current[part] = { ...current[part] };
    current = current[part];
  }

  const lastPart = parts[parts.length - 1] as keyof typeof current;

  if (value === undefined || value === null) {
    current[lastPart] = value;
  } else {
    current[lastPart] =
      isObject(value) && isObject(current[lastPart])
        ? { ...current[lastPart], ...value }
        : value;
  }

  return newObj;
}
